module.exports = {
  siteTitle: 'RCR Labs', // <title>
  manifestName: 'rcrlabs',
  manifestShortName: 'rcrlabs', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/Logo-500x500.png',
  pathPrefix: `/gatsby-starter-stylish/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'RCR Labs',
  subHeading: 'Research, Create & Run',

  // social
  socialLinks: [
    {
      icon: 'icon-social-github',
      name: 'Github',
      url: 'https://github.com/coolant9',
    },
    {
      icon: 'icon-social-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/rcrlabs',
    },
    {
      icon: 'icon-social-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/rcrlabs',
    },
  ],
  email: 'contact@rcrlabs.com',
  phone: '000-00000',
  address: '485 Speedwell Avenue, Morris Plains, NJ',
};
